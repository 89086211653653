// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { addFlexiCombo } from '@/services/promotion.js'
import { getShopsSimpleList } from '@/services/shops.js'

// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'
import moment from 'moment'
// import el from 'element-ui/src/locale/lang/el'

export default {
  name: 'onlineCouponEdit',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      btnTxt: this.$t('button.next'),
      groupTypeOptions: [
        {
          label: this.$t('form.common.retail'),
          value: 'retail'
        },
        {
          label: this.$t('form.common.wholesale'),
          value: 'wholesale'
        }
      ]
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      this.formData[0].createDateItem[5].options = this.groupTypeOptions
      // getFlexiComboDiscountType().then(res => {
      //   if (res.status === 200) {
      //     this.formData[0].createDateItem[7].options = res.data
      //     this.formData[0].createDateItem[7].value = res.data[0].value
      //   }
      // }).finally(() => {
      //   this.$store.state.app.loading.close()
      // })
      getShopsSimpleList().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[3].options = []
          res.data.shops.forEach((item) => {
            const obj = {
              value: item.id,
              name: item.name
            }
            this.formData[0].createDateItem[3].options.push(obj)
          })
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop, item, cdata) {
      if (prop === 'couponable_type') {
        if (data === 'ShopItem') {
          this.btnTxt = this.$t('button.next')
        } else {
          this.btnTxt = this.$t('button.confirm')
        }
      }
      if (prop === 'deal_criteria' && cdata.discount_type === 'money_value_off' && cdata.stackable_discount) {
        if (cdata.item_quantity_tiers_attributes.length > 0) {
          this.formData[0].createDateItem[8].Object.addFirstDisabled = true
        }
        if (cdata.item_value_tiers_attributes.length > 0) {
          this.formData[0].createDateItem[9].Object.addFirstDisabled = true
        }
      } else {
        this.formData[0].createDateItem[8].Object.addFirstDisabled = false
        this.formData[0].createDateItem[9].Object.addFirstDisabled = false
      }
      if (prop === 'discount_type') {
        if (data === 'percent_off') {
          this.formData[0].createDateItem[8].Object.addFirstDisabled = false
          this.formData[0].createDateItem[9].Object.addFirstDisabled = false
        }
        if (data === 'money_value_off' && cdata.stackable_discount) {
          // this.formData[0].createDateItem[9].Object.addFirstDisabled = true
          if (cdata.item_quantity_tiers_attributes.length > 0) {
            this.formData[0].createDateItem[8].Object.addFirstDisabled = true
            cdata.item_quantity_tiers_attributes = cdata.item_quantity_tiers_attributes.slice(0, 1) || []
            delete cdata.item_quantity_tiers_attributes[0].disabled
          } else {
            this.formData[0].createDateItem[8].Object.addFirstDisabled = false
          }
          if (cdata.item_value_tiers_attributes.length > 0) {
            this.formData[0].createDateItem[9].Object.addFirstDisabled = true
            cdata.item_value_tiers_attributes = cdata.item_value_tiers_attributes.slice(0, 1) || []
            delete cdata.item_value_tiers_attributes[0].disabled
          } else {
            this.formData[0].createDateItem[9].Object.addFirstDisabled = false
          }
        }
      }
    },
    formSubmit (data) {
      console.log('---formSubmit-----', data)
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data.data))
      // const { discount_amount_f, discount_percent, required_quantity } = obj.discount_attributes[0]
      // const begin_time = obj.begin_time_end_time[0]
      // const end_time = obj.begin_time_end_time[1]
      // delete obj.discount_attributes
      // delete obj.begin_time_end_time
      const type = data.data.discount_type
      obj.item_quantity_tiers_attributes.forEach((item) => {
        delete item.disabled
        item.discount_type = type
      })
      obj.begin_time_end_time[0] = moment(obj.begin_time_end_time[0]).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      obj.begin_time_end_time[1] = moment(obj.begin_time_end_time[1]).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      obj.item_value_tiers_attributes.forEach((item) => {
        delete item.disabled
        item.discount_type = type
      })
      const params = {
        flexi_combo: {
          ...obj
        }
      }
      console.log(obj)

      addFlexiCombo(params, this.$route.params.id).then(res => {
        if (res.status === 200) {
          // this.$message({
          //   type: 'success',
          //   message: this.$t('message.operateSuccess')
          // })
          if (this.btnTxt === this.$t('button.confirm')) {
            setTimeout(() => {
              this.$router.push({
                name: 'flexiComboList'
              })
            }, constant.ROUTER_CHANGE_INTERVAL)
          } else {
            this.$router.push({
              name: 'flexiComboroductList',
              query: {
                id: res.data.flexi_combo.id,
                shop_id: res.data.flexi_combo.shop_id
              }
            })
          }
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleCancelClick () {
      this.$router.back(-1)
    },
    afterAdd (creatData, alterData, prop) {
      const length = creatData[prop.prop].length
      const item = creatData[prop.prop][length - 1]
      console.log(creatData)
      if (creatData.deal_criteria === 'item_quantity') {
        alterData.required_items = item ? Number(item.required_items) + 1 : 10
      } else {
        alterData.required_value_f = item ? Number(item.required_value_f) + 1 : 1000
      }
      alterData.discount_value_f = item ? Number(item.discount_value_f) + 1 : 100
      alterData.discount_percent = item ? Number(item.discount_percent) + 1 : 10
      if (item) {
        creatData[prop.prop][length - 1].disabled = true
      }
      creatData[prop.prop].push(alterData)
      this.checkDisable(creatData)
    },
    removeArray (createData, obj) {
      if (obj.index - 1 > -1) {
        delete createData[obj.prop][obj.index - 1].disabled
      }
      createData[obj.prop].splice(obj.index, 1)
      this.checkDisable(createData)
    },
    checkDisable (createData) {
      if (createData.discount_type === 'money_value_off' && createData.stackable_discount) {
        if (createData.item_value_tiers_attributes.length > 0) {
          this.formData[0].createDateItem[9].Object.addFirstDisabled = true
        } else {
          this.formData[0].createDateItem[9].Object.addFirstDisabled = false
        }
        if (createData.item_quantity_tiers_attributes.length > 0) {
          this.formData[0].createDateItem[8].Object.addFirstDisabled = true
        } else {
          this.formData[0].createDateItem[8].Object.addFirstDisabled = false
        }
      }
    },
    handleSwitchChange (value, createDate, prop) {
      if (prop === 'stackable_discount') {
        if (createDate.deal_criteria === 'item_value') {
          if (value && createDate.item_value_tiers_attributes.length > 0) {
            this.formData[0].createDateItem[9].Object.addFirstDisabled = true
            createDate.item_value_tiers_attributes = createDate.item_value_tiers_attributes.slice(0, 1) || []
            delete createDate.item_value_tiers_attributes[0].disabled
          }
          if (!value || createDate.item_value_tiers_attributes.length < 1) {
            this.formData[0].createDateItem[9].Object.addFirstDisabled = false
          }
        } else {
          if (value && createDate.item_quantity_tiers_attributes.length > 0) {
            this.formData[0].createDateItem[8].Object.addFirstDisabled = true
            createDate.item_quantity_tiers_attributes = createDate.item_quantity_tiers_attributes.slice(0, 1) || []
            delete createDate.item_quantity_tiers_attributes[0].disabled
          }
          if (!value || createDate.item_quantity_tiers_attributes.length < 1) {
            this.formData[0].createDateItem[8].Object.addFirstDisabled = false
          }
        }
      }
      if (prop === 'entire_shop') {
        if (value) {
          this.btnTxt = this.$t('button.confirm')
        } else {
          this.btnTxt = this.$t('button.next')
        }
      }
    }
  }
}
